<template>
    <Toast/>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">

                <div class="surface-section">
                    <div class="font-medium text-3xl text-900 mb-3 flex main-top">
                        <!--<div style="height:50px;width:50px;border-radius:30px;overflow:hidden;display:inline-block;margin-right:10px;">
                            <img :src="imageUrl" onerror="javascript:this.src='images/coachera-logo-sm.png'" class="mr-4" style="height:50px;">
                        </div>-->
                        <Image
                            :src="imageUrl"
                            :preview=true
                            :imageStyle="{'border-radius': '50px', 'height': '40px'}"
                            onerror="javascript:this.src='images/ludi_placeholder.png'"
                        />
                        <span class="profile-name">{{ profiledata.name }}</span>
                        <div v-if="uid != -1"><Button type="button" icon="pi pi-comment" class="p-button-rounded p-button-outlined mr-2 ml-2" label="Chat" v-on:click="comment(profiledata.id)"></Button></div>
                        <div v-if="uid == -1" style="margin-left:auto;"><Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-outlined mr-2 ml-2" label="Edit" v-on:click="editProfile"></Button></div>
                    </div>
                    <!--<div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div>-->
                    <ul class="list-none p-0 m-0">
                        <li v-if="profiledata.ytlink != '' && profiledata.ytlink != undefined" class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="ytvideo" v-if="profiledata.ytlink != '' && profiledata.ytlink != undefined">
                            <iframe width="560" height="340" :src="profiledata.ytlink.replace('watch?v=', 'embed/')"
                                title=""
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                            </iframe>
                            </div>
                        </li>

                        <li v-if="uid == -1" class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">E-Mail</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ profiledata.email }}</div>
                            <!--<div class="w-6 md:w-2 flex justify-content-end">
                                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                            </div>-->
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Country</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ profiledata.country }}</div>
                            <!--<div class="w-6 md:w-2 flex justify-content-end">
                                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                            </div>-->
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Priorities</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 flex flex-wrap">
                                <div v-for="(label, index) in labels" :key="index">
                                    <!--<div class="p-chip p-component mr-2 mb-2 p-2" :style="dynamicStyle(index)" :class="{ [colors[index]]: true }">{{ label }}</div>-->
                                    <div class="p-chip p-component mr-2 my-1 p-2" :style="{'color': foregroundColor, 'background-color': colors[index]}">{{ label }}</div>
                                </div>
                            </div>
                            <!--<div class="w-6 md:w-2 flex justify-content-end">
                                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                            </div>-->
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Price range</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ profiledata.pricerange }}</div>
                            <!--<div class="w-6 md:w-2 flex justify-content-end">
                                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                            </div>-->
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">In a nutshell</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.nutshell }}</div>
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">About yourself</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.about }}</div>
                            <!--<div class="w-6 md:w-2 flex justify-content-end">
                                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>
                            </div>-->
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Education</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.education }}</div>
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Work experience</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.work_experience }}</div>
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Coaching experience</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">{{ profiledata.coaching_experience }}</div>
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Socials</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">

                                <a v-if="profiledata.ytlink != undefined && profiledata.ytlink != ''" :href="profiledata.ytlink">
                                    <Button icon="pi pi-youtube" class="p-button-rounded p-button-outlined mr-2 mb-2" />
                                </a>
                                <a v-if="profiledata.linkedinlink != undefined && profiledata.linkedinlink != ''" :href="profiledata.linkedinlink">
                                    <Button icon="pi pi-linkedin" class="p-button-rounded p-button-outlined mr-2 mb-2" />
                                </a>
                                <a v-if="profiledata.twitterlink != undefined && profiledata.twitterlink != ''" :href="profiledata.twitterlink">
                                    <Button icon="pi pi-twitter" class="p-button-rounded p-button-outlined mr-2 mb-2" />
                                </a>

                            </div>
                        </li>

                        <li class="flex align-items-center py-4 px-2 border-top-1 surface-border flex-wrap">
                            <div class="text-500 w-6 md:w-2 font-medium">Website</div>
                            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 textarea">
                                <a :href="profiledata.websitelink">{{ profiledata.websitelink }}</a>
                            </div>
                        </li>
                    </ul>
                </div>


                <hr>
                <div v-if="allowRating" class="flex" style="justify-content: center;">
                Rate this coach:&nbsp;<br><br> <Rating :modelValue="rating" :readonly="false" :cancel="false" v-on:change="updateRating" />
                </div>

			</div>
		</div>
	</div>

</template>

<script>
import UserService from '../service/UserService';

export default {
    props: {
        uid: {
            default: -1
        }
    },
    data() {
        return {
            profiledata: {},
            labels: ['Grow', 'Lead', 'Balance', 'Change', 'Escape', 'Reflect'],
            colors: ['green', 'green', 'green', 'green', 'green', 'green'],
            allowRating: false,
            rating: 0
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    mounted() {
        this.userService.getRating(this.uid).then(data => {
            this.rating = data['rating'];
        });

        this.userService.getMessages(this.uid).then(data => {
            this.allowRating = data.length >= 4;
        });

        this.userService.getUser(this.uid).then(data => {
            if (!data || data == null || typeof(data) === "undefined") {
                 this.$toast.add({severity:'error', summary: 'This profile does not exist.', life: 20000}); 
            } else {
                this.profiledata = data;
            }

            var cols = this.userService.getColors();

            this.colors = [];
            for (var i=0; i < 6; i++) {
                var prio = this.profiledata['priorities'][i];
                if (prio === 0) {
                    // this.colors.push("green1");
                    this.colors.push(cols[1]);
                } else if (prio === 2.5) {
                    // this.colors.push("green2");
                    this.colors.push(cols[2]);
                } else if (prio === 5) {
                    // this.colors.push("green3");
                    this.colors.push(cols[3]);
                }
            }
        });
    },
    computed: {
        imageUrl() {
            return "images/" + this.profiledata.id + ".jpg";
        },
        foregroundColor() {
            return this.userService.getColors()[0];
        }
    },
    methods: {
        comment(uid) {
            this.userService.saveConversation({"uid": uid}).then(data => {
                this.$router.push({ name: 'conversations', params: { uid: uid } });
            });
        },
        editProfile() {
            this.$router.push({ name: 'editprofile' });
        },
        updateRating(event) {
            this.userService.updateRating({"uid": this.profiledata.id, "rating": event['value']}).then(() => {
                this.$toast.add({severity: 'info', summary: 'Success', detail: 'Saved rating.', life: 3000});
            });
            this.rating = event['value'];
        }
    }
};
</script>

<style lang="scss" scoped>
.surface-section {
    margin-top: 20px;
    width: 100%;
}
.main-top {
    display: flex;
    align-items: center;
}
.p-chip {
    padding: 10px!important;
    border-radius: 20px;
}
.green1 {
    /* background-color: #83D475; */
    background-color: #3944bc;
    color: white;
}
.green2 {
    /* background-color: #ABE098; */
    background-color: #6069c8;
    color: white;
}
.green3 {
    /* background-color: #d7f7ca; */
    background-color: #888ed5;
    color: white;
}
.profile-name {
    margin-left: 10px;
}
.p-image {
    height: 40px;
}
.textarea {
    white-space: pre-wrap;
}
.ytvideo {
    margin: 0 auto!important;
}
</style>
